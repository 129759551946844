<template>
  <v-card
    id="list-card"
    ref="ready"
    class="mx-auto mt-n4"
    max-width="300"
    height="size"
    tile
    flat
  >
    <v-list
      nav
      dense
      class="overflow-y-auto"
      :max-height="size"
    >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
          @click="emitSelection('')"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('common|all') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
          @click="emitSelection('none')"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('common|uncategorized') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div v-if="actualTab && actualTab.isLavviraTab">
          <v-list-item
            v-for="(item, i) in settings.lavviraSettings.lavviraCaseModelsCategories"
            :key="i"
            :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
            @click="emitSelection(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.value"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="actualTab && actualTab.isMyTab">
          <v-list-item
            v-for="(item, i) in account.myCaseModelsCategories"
            :key="i"
            :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
            @click="emitSelection(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.value"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="actualTab && actualTab.isCompanyTab">
          <v-list-item
            v-for="(item, i) in company.companyCaseModelsCategories"
            :key="i"
            :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
            @click="emitSelection(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.value"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="actualTab && actualTab.isGroupTab">
          <v-list-item
            v-for="(item, i) in actualGroup.groupCaseModelsCategories"
            :key="i"
            :style="`background-color: ${$vuetify.theme.themes.light.lightBackground};`"
            @click="emitSelection(item.value)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.value"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedItem: '',
      size: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      settings: state => state.settings.settings
    }),
    actualGroup () {
      let index
      if (this.actualTab && this.actualTab.group) {
        index = this.company.groups.findIndex(gr => gr._id === this.actualTab.group._id)
      }
      return this.company.groups[index]
    }
  },
  mounted () {
    if (this.$refs.ready) {
      this.getSize()
    }
  },
  methods: {
    emitSelection (item) {
      EventBus.$emit('category-is-selected', item)
    },
    getSize () {
      const element = document.getElementById('template-category-btn')
      if (element) {
        let value = document.getElementById('template-category-btn').getBoundingClientRect().y
        if (value) {
          let num = parseFloat(value)
          let val = num - (num * 0.50)
          this.size = val.toString() + 'px'
        }
      }
    }
  }
}
</script>
